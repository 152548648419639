<template>
  <section :class="$style.article">
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Вакансия" prop="name">
        <el-input
          v-model="form.name"
          @focus="!form.slug ? handleNameInput(form.name) : ''"
          @input="handleNameInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="Название ссылки" prop="slug">
        <el-input v-model="form.slug" clearable></el-input>
      </el-form-item>
      <el-form-item
        v-for="(value, index) in form.requirements"
        :key="index + 'requirements'"
        label="Требования"
        :prop="'requirements.' + index"
        :rules="rules.requirements"
      >
        <div :class="$style.deleteButtonWrapper">
          <el-input v-model="form.requirements[index]"></el-input>
          <el-button
            type="danger"
            plain
            circle
            icon="el-icon-delete"
            :class="$style.remove"
            @click="removeItem(index, 'requirements')"
          />
        </div>
      </el-form-item>
      <el-form-item prop="requirements" label-width="0">
        <el-button type="primary" plain @click="addItem('requirements')">
          Добавить требование
        </el-button>
      </el-form-item>
      <el-form-item label="Занятость" prop="employment">
        <el-input v-model="form.employment"></el-input>
      </el-form-item>
      <el-form-item
        v-for="(value, index) in form.conditions"
        :key="index + 'conditions'"
        label="Условия"
        :prop="'conditions.' + index"
        :rules="rules.conditions"
      >
        <div :class="$style.deleteButtonWrapper">
          <el-input v-model="form.conditions[index]"></el-input>
          <el-button
            type="danger"
            plain
            circle
            icon="el-icon-delete"
            :class="$style.remove"
            @click="removeItem(index, 'conditions')"
          />
        </div>
      </el-form-item>
      <el-form-item prop="conditions" label-width="0">
        <el-button
          prop="conditions"
          type="primary"
          plain
          @click="addItem('conditions')"
        >
          Добавить Условие
        </el-button>
      </el-form-item>
      <el-form-item label="Опыт работы" prop="experience">
        <el-input v-model="form.experience"></el-input>
      </el-form-item>
      <el-form-item
        v-for="(value, index) in form.responsibilities"
        :key="index + 'responsibilities'"
        label="Обязанности"
        :prop="'responsibilities.' + index"
        :rules="rules.responsibilities"
      >
        <div :class="$style.deleteButtonWrapper">
          <el-input v-model="form.responsibilities[index]"></el-input>
          <el-button
            type="danger"
            plain
            circle
            icon="el-icon-delete"
            :class="$style.remove"
            @click="removeItem(index, 'responsibilities')"
          />
        </div>
      </el-form-item>
      <el-form-item prop="responsibilities" label-width="0">
        <el-button type="primary" plain @click="addItem('responsibilities')">
          Добавить Обязанность
        </el-button>
      </el-form-item>
      <el-form-item label="Дата публикации" prop="publishedAt">
        <el-date-picker
          v-model="form.publishedAt"
          type="date"
          :class="$style.date"
          placeholder="Выберите дату"
          :picker-options="datePickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="В архиве">
        <el-checkbox v-model="form.isArchived" />
      </el-form-item>
      <SeoBlock
        style="margin-top: 2rem"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
      <el-button type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
    </el-form>
  </section>
</template>

<script>
import delivery from '@/delivery'
import { transliterate } from '@/helpers/slug'
import regexp from '@/helpers/regexp.js'
import SeoBlock from '@/components/moleculs/SEO.vue'
import notifications from '@/mixins/notifications'

export default {
  components: {
    SeoBlock,
  },
  mixins: [notifications],
  data() {
    return {
      form: {
        name: '',
        slug: '',
        experience: '',
        isArchived: false,
        requirements: [],
        conditions: [],
        responsibilities: [],
        employment: '',
        publishedAt: '',
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        employment: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        experience: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        requirements: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        responsibilities: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        conditions: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        publishedAt: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  async created() {
    await this.getVacancies()
  },
  methods: {
    async getVacancies() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.VacanciesActions.getById(
          this.$route.params.id,
        )
      if (error) return

      this.form = value

      loading.close()
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
    addItem(fieldName) {
      switch (fieldName) {
        case 'requirements': {
          this.form.requirements.push('')
          break
        }
        case 'conditions': {
          this.form.conditions.push('')
          break
        }
        case 'responsibilities': {
          this.form.responsibilities.push('')
          break
        }
      }
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          if (!this.form.conditions.length) {
            this.showNotification('Нужно добавить условия', 'error')
            loading.close()
            return
          }

          if (!this.form.requirements.length) {
            this.showNotification('Нужно добавить требования', 'error')
            loading.close()
            return
          }

          if (!this.form.responsibilities.length) {
            this.showNotification('Нужно добавить обязанности', 'error')
            loading.close()
            return
          }

          const data = { ...this.form }
          const { error } =
            await delivery.ContentServiceCore.VacanciesActions.update(
              this.$route.params.id,
              data,
            )
          if (!error) {
            this.showNotification('Вакансия успешно отредактирована', 'success')
            this.$router.push('/addwine/content/vacancies')
          }
          loading.close()
        }
      })
    },
    removeItem(index, fieldName) {
      this.form[fieldName].splice(index, 1)
    },
  },
}
</script>

<style lang="scss" module>
.article {
  padding: 1rem;

  .form {
    & > div > label {
      text-align: left;
    }

    .date {
      width: 100%;
    }
  }
}
.deleteButtonWrapper {
  display: flex;
  gap: 1rem;
}
</style>
